<template>
  <div class="projects">
    <b-container fluid v-if="items.length > 0">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col xl="6" class="p-xl-2 p-4" v-for="(item, index) in items" :key="index">
              <project-tile :item="item" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-col cols="12" class="d-flex-justify-center mt-5">
        <b-button class="button button--filled" @click="viewAll = true">{{
          $t("button.see_more")
        }}</b-button>
      </b-col>-->
      <!-- <b-row v-if="viewAll">
        <b-col
          class="p-xl-2 p-4"
          cols="12"
          md="6"
          v-for="(tile, i) in fullViewItems"
          :key="i"
        >
          <project-tile :item="tile" />
        </b-col>
      </b-row>-->
    </b-container>
  </div>
</template>

<script>
export default {
  components: {
    projectTile: () => import("./project-tile")
  },

  props: {
    fullView: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      viewAll: false
    };
  },

  computed: {
    secondCol() {
      return this.items.filter((el, i) => i >= 1 && i <= 4);
    },
    fullViewItems() {
      return this.items.filter((el, i) => i >= 5);
    }
  }
};
</script>
